import React, { useContext } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
// eslint-disable-next-line no-unused-vars
import {
  Container,
  // Carousel,
  Row,
  Accordion,
  Card,
  AccordionContext,
  useAccordionToggle,
} from "react-bootstrap"
import cpp from "../images/childProtectionPolicy/banner.png"

// eslint-disable-next-line react/prop-types
function ContextAwareToggle({ children, eventKey, callback }) {
  const currentEventKey = useContext(AccordionContext)

  const decoratedOnClick = useAccordionToggle(
    eventKey,
    () => callback && callback(eventKey)
  )
  const isCurrentEventKey = currentEventKey === eventKey
  return (
    <div
      type="button"
      className={isCurrentEventKey ? "show" : ""}
      onClick={decoratedOnClick}
    >
      {children}
    </div>
  )
}

const ChildProtectionPolicy = () => {
  return (
    <Layout pageName="Child Protection Policy">
      <SEO title="Child Protection Policy" />
      <Row className="mx-0 z-50">
        <div className="col-lg-6 order-lg-last px-0 jtf-img halfwayBannerColor">
          <img className="w-100" src={cpp} />
        </div>
        <div className="col-lg-6 bg-sec px-0 align-center">
          <div className="jtf-cont">
            <div className="text-center text-lg-left">
              <p className="ed-title">Things to know</p>
              <p className="fi-head mb-lg-3 mb-3">CHILD PROTECTION POLICY</p>
              <p className="fi-txt  mb-lg-3 mb-4">
                Teach To Lead is committed to protecting the safety and
                well-being of children. Thus, Teach To Lead has adopted this
                Child Protection Policy (CPP) requiring organisation
                representatives to conduct themselves appropriately with the
                children who participate in Teach To Lead related programs as
                well as take reasonable measures to ensure the safety of such
                children.
              </p>
              <p className="fi-txt  mb-lg-3 mb-4">
                This policy aims to define the behaviours and actions that
                constitute child abuse. Additionally, it will lay out the
                organisations expectations for behaviour, the scope of
                application of the policy and guidelines for reporting and
                redressal.
              </p>
            </div>
          </div>
        </div>
      </Row>

      <Container className="py-lg-5">
        <Accordion className="cpp-acc" defaultActiveKey="10">
          <Card>
            <Card.Header>
              <ContextAwareToggle eventKey="0">
                <Row>
                  <div className="col-lg-5 pr-5">
                    <p className="wn-title">What Constitutes Child Abuse?</p>
                  </div>
                  <div className="col-lg-7 cpp-cont">
                    <p className="fi-txt">
                      Child abuse or maltreatment constitutes all forms of
                      physical and/or emotional ill-treatment, sexual abuse,
                      neglect or negligent treatment or commercial or other
                      exploitation, resulting in actual child abuse or
                      maltreatment constitutes all forms of physical and/or
                      emotional ill-treatment, sexual abuse, neglect or
                      negligent treatment or commercial or other exploitation,
                      resulting in actual or potential harm to the child’s
                      health, survival, development or dignity in the context of
                      a relationship of responsibility, trust or power (Source:
                      A report of the consultation on child abuse prevention,
                      WHO, Geneva, 29-31 March 1999, defines child abuse as).
                    </p>
                    <p className="fi-txt">
                      Teach To Lead Staff and Fellows must be aware of the
                      following types of abuse and understand that they are
                      prohibited as per this policy.
                    </p>
                    <p className="fi-txt">
                      <span className="bolder">Physical abuse:</span> The
                      intentional use of physical force against a child that
                      results in or has a high likelihood of resulting in harm
                      to the child’s health, survival, development or dignity.
                      This includes hitting, beating, kicking, shaking, biting,
                      strangling, scalding, burning, poisoning and suffocating.
                    </p>
                    <p className="fi-txt">
                      <span className="bolder">Emotional abuse:</span> Emotional
                      abuse includes the failure to provide a developmentally
                      appropriate, supportive environment, so that the child can
                      develop a stable and full range of emotional and social
                      competencies commensurate with their personal potential
                      and in the context of the society in which the child
                      dwells. Acts include restriction of movement, patterns of
                      belittling, denigrating, scapegoating, threatening,
                      scaring, discriminating, ridiculing or other non-physical
                      forms of hostile treatment.
                    </p>
                    <p className="fi-txt">
                      <span className="bolder">
                        Neglect and negligent treatment​:
                      </span>{" "}
                      We hold ourselves responsible for all children in our
                      class to be free from our neglect. Neglect is the failure
                      on the part of the parents, guardians, teachers or other
                      responsible parties to provide for the child’s basic
                      needs, such as food, shelter, medical care, educational
                      opportunities, or protection and supervision. It is abuse
                      through omission of the necessary environment and
                      resources for the child to reach their full potential; and
                      the act of not recognizing physical or emotional abuse.
                    </p>
                    <p className="fi-txt">
                      <span className="bolder"> Exploitation: </span>Commercial
                      or other exploitation of a child refers to use of the
                      child in work or other activities for the benefit of
                      others. This includes, but is not limited to, child labour
                      and child prostitution. These activities are to the
                      detriment of the child’s physical or mental health,
                      education, or spiritual, moral or social-emotional
                      development.
                    </p>
                    <p className="fi-txt">
                      <span className="bolder"> Sexual abuse:</span> - Sexual
                      abuse is an act or behaviour which constitutes a sexual
                      offence under the Protection of Children from Sexual
                      Offences Act, 2012, as amended from time to time. Such
                      sexual offences include penetrative sexual assault
                      (including rape), non-penetrative sexual assault
                      (including touching the private parts of a child), sexual
                      harassment (including stalking a child, showing a child
                      pornography and making a child exhibit his/her body) and
                      using a child for pornographic purposes (including storing
                      of pornographic material involving a child for commercial
                      purposes). - An abetment of and an attempt to commit a
                      sexual offence under the Protection of Children from
                      Sexual Offences Act, 2012 are also considered to be
                      offences. Certain offences under the Protection of
                      Children from Sexual Offences Act, 2012 are considered to
                      be aggravated offences and are subject to stringent
                      punishments. For instance, sexual assault committed by
                      persons in a position of trust or authority in relation to
                      the child (including relatives of the child, persons
                      managing or working in an educational institution, police
                      officers or public servants) are considered to be
                      aggravated offences.
                    </p>

                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </Row>
              </ContextAwareToggle>
            </Card.Header>
          </Card>
          <Card>
            <Card.Header>
              <ContextAwareToggle eventKey="1">
                <Row>
                  <div className="col-lg-5 pr-5">
                    <p className="wn-title">
                      Scope of Application of the Child Protection Policy
                    </p>
                  </div>
                  <div className="col-lg-7 cpp-cont">
                    <p className="fi-txt">
                      This CPP is applicable to all part-time and full-time
                      employees (including but not limited to Staff, Fellows,
                      Interns) of Teach To Lead and any other person who has
                      been bound to the Child Protection Policy as per their
                      contractual agreement with Teach To Lead.
                    </p>
                    <p className="fi-txt">
                      This policy will be shared and explained to all school
                      HMs/Lead teachers.
                    </p>
                    <p className="fi-txt">
                      Our government schools are governed by their respective
                      state level Child Protection Policies.
                    </p>
                    <p className="fi-txt">
                      We will encourage our low income private schools to adopt
                      this policy if they do not have a similar one of their
                      own.
                    </p>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </Row>
              </ContextAwareToggle>
            </Card.Header>
          </Card>
          <Card>
            <Card.Header>
              <ContextAwareToggle eventKey="2">
                <Row className="cpp-sp">
                  <div className="col-lg-5 pr-5">
                    <p className="wn-title">Expected Conduct</p>
                  </div>
                  <div className="col-lg-7 cpp-cont">
                    <Row>
                      <div className="col-lg-6">
                        <p className="fi-txt bolder">
                          Expected Behaviour and Actions
                        </p>
                        <p className="fi-txt">
                          Treat every child with empathy and respect, regardless
                          of their race, colour, gender, sexuality, language,
                          religion, religious belief, heritage, political/ other
                          opinion, national/ ethnic/ social origin or property/
                          disability/ birth/ other status.
                        </p>
                        <p className="fi-txt">
                          Listen to children and respect their views.
                        </p>
                        <p className="fi-txt">
                          Ensure that physical contact with a child is
                          respectful, culturally appropriate and essential to
                          the purpose of your interaction with the child.
                        </p>
                        <p className="fi-txt">
                          Establish an atmosphere that fosters the development
                          of children through your actions and words.
                        </p>
                        <p className="fi-txt">
                          Always take permission from children before taking
                          their photos or videos.
                        </p>
                        <p className="fi-txt">
                          Keep all personal information about children or their
                          parents/guardians confidential and secure and ensure
                          that such information is dispensed to only those
                          individuals who are legitimately entitled to it.
                        </p>
                        <p className="fi-txt">
                          Attend all the applicable training sessions and
                          workshops on the CPP and child-related laws conducted
                          by the organization. Ensure you implement the lessons
                          learned from such sessions and workshops.
                        </p>
                      </div>
                      <div className="col-lg-6 d-none d-lg-block">
                        <p className="fi-txt bolder">
                          Prohibited Behaviour and Actions
                        </p>
                        <p className="fi-txt">
                          Do not use language or behaviour towards children that
                          is inappropriate, harassing, abusive, sexually
                          provocative, demeaning, intimidating, offensive,
                          discriminatory or culturally insensitive.
                        </p>
                        <p className="fi-txt">
                          Do not use any form of corporal punishment on
                          children.
                        </p>
                        <p className="fi-txt">
                          Do not place a child in an environment where there is
                          the risk of harm or abuse and do not harm or abuse any
                          child physically, emotionally or sexually.
                        </p>
                        <p className="fi-txt">
                          Do not share with or show children (electronically or
                          in any other form) inappropriate content including
                          pornographic material or material encouraging crime,
                          violence, terrorism, racism, sexism, self-harm,
                          suicide, cruelty and gambling.
                        </p>
                        <p className="fi-txt">
                          Do not develop, induce or support physical or sexual
                          relationships with children, in any way.
                        </p>
                        <p className="fi-txt">
                          Do not use or encourage the use of alcohol, drugs,
                          cigarettes or other intoxicating substances while
                          interacting with children or provide such intoxicating
                          substances to children.
                        </p>
                        <p className="fi-txt">
                          Do not develop any form of relationship or arrangement
                          (financial or otherwise) with children which could in
                          any way be deemed to be exploitative or abusive. Do
                          not use child labour in any form.
                        </p>
                      </div>
                    </Row>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </Row>
              </ContextAwareToggle>
            </Card.Header>
          </Card>
          <Card className="d-block d-lg-none">
            <Card.Header>
              <ContextAwareToggle eventKey="20">
                <Row className="cpp-sp">
                  <div className="col-lg-5 pr-5">
                    <p className="wn-title">Expected Conduct</p>
                  </div>
                  <div className="col-lg-7 cpp-cont">
                    <Row>
                      <div className="col-lg-6">
                        <p className="fi-txt bolder">
                          Prohibited Behaviour and Actions
                        </p>
                        <p className="fi-txt">
                          Do not use language or behaviour towards children that
                          is inappropriate, harassing, abusive, sexually
                          provocative, demeaning, intimidating, offensive,
                          discriminatory or culturally insensitive.
                        </p>
                        <p className="fi-txt">
                          Do not use any form of corporal punishment on
                          children.
                        </p>
                        <p className="fi-txt">
                          Do not place a child at risk of harm or abuse and do
                          not harm or abuse any child physically, emotionally or
                          sexually.
                        </p>
                        <p className="fi-txt">
                          Do not share with or show children (electronically or
                          in any other form) inappropriate content including
                          pornographic material or material encouraging crime,
                          violence, terrorism, racism, sexism, self-harm,
                          suicide, cruelty and gambling.
                        </p>
                        <p className="fi-txt">
                          Do not develop, induce or support physical or sexual
                          relationships with children, in any way.
                        </p>
                        <p className="fi-txt">
                          Do not use or encourage the use of alcohol, drugs,
                          cigarettes or other intoxicating substances while
                          interacting with children or provide such intoxicating
                          substances to children.
                        </p>
                        <p className="fi-txt">
                          Do not develop any form of relationship or arrangement
                          (financial or otherwise) with children which could in
                          any way be deemed to be exploitative or abusive. Do
                          not use child labour in any form.
                        </p>
                      </div>
                    </Row>
                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </Row>
              </ContextAwareToggle>
            </Card.Header>
          </Card>
          <Card>
            <Card.Header>
              <ContextAwareToggle eventKey="3">
                <Row>
                  <div className="col-lg-5 pr-5">
                    <p className="wn-title">
                      Teach To Lead’s Measures to Prevent Child Abuse
                    </p>
                  </div>
                  <div className="col-lg-7 cpp-cont">
                    <p className="fi-txt bolder">
                      {" "}
                      On-Boarding Staff and Fellows:
                    </p>

                    <p className="fi-txt">
                      • All Fellows and Staff must self declare previous cases
                      of criminal offence and child mistreatment as part of
                      their application to Teach To Lead.
                    </p>

                    <p className="fi-txt">
                      • All Fellows and Staff will undergo child protection
                      awareness training.
                    </p>

                    <p className="fi-txt">
                      • Enforce child safety guidelines that are required to be
                      followed by the Staff and Fellows during excursions,
                      picnics and educational tours. Our Field Trip Policy is
                      included in Annexure 6.
                    </p>

                    <p className="fi-txt">
                      • Ensure that all Fellows and Staff sign a copy of the
                      Child Protection Policy.
                    </p>

                    <p className="fi-txt">
                      • Attempt to ensure that visitors to a school are
                      accompanied by a Staff Member or a Fellow.
                    </p>

                    <p className="fi-txt">
                      • Where the organization undertakes any research on
                      children or collects data on them, ensure that children
                      are not harmed or traumatized in any way during the
                      process.
                    </p>

                    <p className="fi-txt">
                      • Partnerships to Support Implementation where possible -
                      Partner with organisations to conduct age-appropriate
                      sessions on the personal safety of Students (including
                      modules/materials on body parts, online safety, protection
                      from child abuse and services available for the protection
                      of children). Partner with organisations working in the
                      area of child abuse and child rights, to support Staff and
                      Fellows in effective implementation of the CPP.
                    </p>

                    <p className="fi-txt">
                      <span className="bolder">Redressal Mechanisms: </span>
                      Assigning responsibility to specific individuals or groups
                      of individuals within the organisation to ensure
                      procedures and arrangements are in place for effective
                      implementation of the policy and redressal of child abuse
                      complaints.
                    </p>

                    <p className="fi-txt">These include:</p>

                    <p className="fi-txt">
                      <span className="bolder">
                        City Child Protection Officer (CCPO):{" "}
                      </span>
                      This is the nodal person in every city to ensure
                      compliance to the CPP. List of names and contact details
                      of the CCPO is provided in Annexure 5. For any type of
                      this policy, please reach out to your CCPO.
                    </p>

                    <p className="fi-txt">
                      <span className="bolder">
                        City Child Protection Committee (CCPC):{" "}
                      </span>
                      This is a committee in a city to help the CCPO with
                      redressal decisions and will conduct reviews (once every 2
                      months) of the working of the CPP in the city and
                      implement any additional measures to strengthen child
                      protection measures.
                    </p>

                    <p className="fi-txt">
                      <span className="bolder">
                        National Child Protection Officer (NCCPO):{" "}
                      </span>
                      This is a person who oversees the policy at a national
                      level to help the CCPCs with redressal decisions when
                      escalated. He/she will conduct reviews (once every 3
                      months) of the working of the CPP in the organisation and
                      implement any additional measures to strengthen child
                      protection measures.
                    </p>

                    <p className="fi-txt bolder">
                      The constitution, roles and responsibilities of CCPO, CCPC
                      and NCCPO is provided in Annexure 3.
                    </p>

                    <p className="fi-txt">
                      There are 3 scenarios under which the Child Protection
                      Policy can be violated. The guidelines set out in this
                      section will govern the reporting and redressal of each of
                      the respective scenarios: - Where the alleged victim is
                      any child (Teach To Lead or non-Teach To Lead) and the
                      alleged offender is a Staff or Fellow - Where the alleged
                      victim is a Teach To Lead student and the alleged offender
                      is not a Staff or Fellow. - Where the alleged victim can
                      be any child and the alleged offender is anybody and where
                      the offence is one of sexual abuse.
                    </p>

                    <span className="acc-plus-icon">+</span>
                    <span className="acc-minus-icon">-</span>
                  </div>
                </Row>
              </ContextAwareToggle>
            </Card.Header>
          </Card>
        </Accordion>
      </Container>
      <Container>
        <p className="fi-txt text-center mb-5 pb-lg-5 mt-4 mt-lg-0">
          For Guidelines on Complaint Redressal Mechanism and additional
          information{" "}
          <a
            href="https://www.tfaforms.com/4724080"
            target="_blank"
            rel="noreferrer noopener"
            className="fi-link"
          >
            click here
          </a>
        </p>
      </Container>
    </Layout>
  )
}

export default ChildProtectionPolicy
